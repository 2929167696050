<ng-container>
  <app-toolbar></app-toolbar>

  <div class="flex flex-col">
      <div class="flex flex-auto mt-large gap-x-30px">
          <!-- Vertical menu -->
          <div class="w-menu offset-top-left hidden flex-none lg:block"></div>

          <!-- Page content-->
          <div class="w-full" id="routerOutlet">

            <mat-card appearance="outlined" class="flex flex-col mt-medium p-medium w-full">
              <div>
                <h1 translate="userSession.title"></h1>
              </div>
              <mat-card-content>
                <div class="flex flex-col">
                  <code>{{ userSessionId }}</code>
                  <app-evida-button class="mt-medium" type="secondary" (clicked)="onCopyUserSessionId()">
                    {{ 'shared.buttons.copyToClipboard' | translate }}
                  </app-evida-button>
                  <app-evida-button class="mt-medium" type="secondary" (clicked)="onResetUserSessionId()">
                    {{ 'userSession.resetIdButton' | translate }}
                  </app-evida-button>
                  <app-evida-button class="mt-medium" type="secondary" (clicked)="onBack()">
                    {{ 'userSession.backButton' | translate }}
                  </app-evida-button>
                </div>
              </mat-card-content>
            </mat-card>

          </div>

          <!-- Vertical menu -->
          <div class="w-menu offset-top-left hidden flex-none lg:block"></div>
      </div>
  </div>
</ng-container>
