import { Injectable } from '@angular/core'
import { all as allCookies, erase as eraseCookie } from 'browser-cookies'
import { environment } from 'src/environments/environment'

@Injectable()
export class QueueItLoaderService {
  public load() {
    return new Promise<void>(async (resolve) => {
      if (!environment.queueIt.enabled) {
        resolve()
        return
      }
      try {
        this.clearSessionQueueItCookiesDomain()
        const queueclientScript = document.createElement('script')
        queueclientScript.src = '//static.queue-it.net/script/queueclient.min.js'
        document.head.appendChild(queueclientScript)
        queueclientScript.onerror = () => {
          resolve()
        }
        queueclientScript.onload = () => {
          const queueconfigloaderScript = document.createElement('script')
          queueconfigloaderScript.src = '//static.queue-it.net/script/queueconfigloader.min.js'
          queueconfigloaderScript.setAttribute('data-queueit-intercept', 'true')
          queueconfigloaderScript.setAttribute('data-queueit-spa', 'true')
          queueconfigloaderScript.setAttribute('data-queueit-intercept-domain', environment.queueIt.scriptInterceptDomain)
          queueconfigloaderScript.setAttribute('data-queueit-c', environment.queueIt.customer)
          document.head.appendChild(queueconfigloaderScript)
          queueconfigloaderScript.onload = () => {
            this.waitForQueueitTokenRemoved(resolve)
            queueconfigloaderScript.onerror = () => {
              resolve()
            }
          }
        }

      } catch {
        resolve()
      }
    })
  }

  private waitForQueueitTokenRemoved(resolve: (() => void)) {
    // If queueittoken exist in url, wait a little for QueueIt javascript to set cookie correct
    if (window.location.href.includes('queueittoken=')) {
      setTimeout(() => {
        this.waitForQueueitTokenRemoved(resolve)
      }, 100)
    } else {
      setTimeout(() => {
        resolve()
      }, 100)
    }
  }

  private clearSessionQueueItCookiesDomain() {
    // Queue-it cookies are prefixed with 'QueueITAccepted-'
    const cookies = allCookies()
    for (const key in cookies) {
      if (Object.prototype.hasOwnProperty.call(cookies, key)) {
        if (key.startsWith('QueueITAccepted-')) {
          eraseCookie(key, {
            path: '/',
            domain: '.evida.dk'
          })
        }
      }
    }
  }
}
