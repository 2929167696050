import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { RedirectTo } from '../utils/redirect-to'
import { RxJS } from '../utils/rxjs-utils'
import { Utils } from '../utils/utils'
import { ShortLinkData, ShortLinkService } from './short-link.service'

@Component({
  selector: 'app-short-link',
  templateUrl: './short-link.component.html',
  styleUrls: ['./short-link.component.scss'],
  standalone: false
})
export class ShortLinkComponent implements OnInit {
  public loading$: Observable<boolean>
  public errors$: Observable<any>
  public customerDetails$: Observable<ShortLinkData>

  constructor(
    private shortLink: ShortLinkService,
    private router: Router,
    private actRoute: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    const { data$, error$, loading$ } = Utils.streamsOf(
      this.shortLink.getCustomerDetailsFromShortLinkInfo(this.actRoute)
    )
    this.loading$ = loading$
    this.errors$ = error$.pipe(RxJS.redirectIfError500(this.router))
    this.customerDetails$ = data$.pipe(
      tap(shortLinkData => RedirectTo.readingFlow(this.router, shortLinkData, true)),
      map(shortLinkData => shortLinkData)
    )
  }
}
